<template>
  <div :class="{ 'c-dark-theme': false, animated: false, fadeIn: true }">
    <!-- 알람설정 관리 --------------------------------------------------------------------------------------------->

    <BCard header-tag="header" footer-tag="footer">
      <div slot="header">
         <CIcon name="cilBellExclamation"></CIcon><strong> 경보/알람 설정 </strong>
        <div class="card-header-actions">
          <small class="text-muted">상태별 경보, 경광등, SMS 전송등 경보/알람을 설정합니다.</small>
        </div>
      </div>

      <BRow>
        <BCol xl="6" v-if="this.$store.state.user.role === 'SA'">
          <!-- alarm config -->
          <CCard border-color="info" v-if="alarm!==null">
            <CCardHeader>
              <BRow>
                <BCol>
                  <BIconBellFill class="mr-2"/> 일괄 경보 설정
                </BCol>
                <BCol class="text-right">
                  <BButton variant="primary" type="submit" size="sm" @click="alarmSubmit($event)">
                    <BIconBootstrapReboot/> 설정 반영
                  </BButton>
                </BCol>
              </BRow>
            </CCardHeader>

            <CCardBody>
              <BForm>
              <BTableSimple class="mb-0" small>
                <BTr>
                  <BTd width="30%">
                    경보 사용
                  </BTd>
                  <BTd>
                    <CSwitch class="mr-1" color="danger" :checked.sync="alarm.enabled" label/>
                  </BTd>
                </BTr>
                <BTr>
                  <BTd>
                    콘솔 알람
                  </BTd>
                  <BTd>
                    <BRow class="small font-weight-bold">
                      <BCol sm="3">알림창</BCol>
                      <BCol sm="3"><CSwitch class="mr-1" color="primary" :checked.sync="alarm.console.icon" label/></BCol>
                      <BCol sm="3">비프음</BCol>
                      <BCol sm="3"><CSwitch class="mr-1" color="primary" :checked.sync="alarm.console.sound" label/></BCol>
                      <BCol sm="3">음성</BCol>
                      <BCol sm="3"><CSwitch class="mr-1" color="primary" :checked.sync="alarm.console.tts" label/></BCol>
                    </BRow>
                  </BTd>
                </BTr>

                <BTr>
                  <BTd>
                    상태 경보
                  </BTd>
                  <BTd class="font-weight-bold">
                    <BRow>
                      <BCol sm=6 v-for="item in alarm.events" :prepend="eventMap[item.code]" :key="item._id">
                        <BRow class="text-nowrap small"><BCol>{{ eventMap[item.code] }}</BCol>
                        <BCol><CSwitch color="warning" :checked.sync="item.enabled"  label/></BCol>
                        </BRow>
                      </BCol>
                    </BRow>
                  </BTd>
                </BTr>
                <BTr>
                  <BTd>
                    경광등
                  </BTd>
                  <BTd class="small font-weight-bold">
                    <BRow>
                      <BCol>램프</BCol>
                      <BCol>
                        <CSwitch class="mr-1" color="info" :checked.sync="alarm.lamp.light" label/>
                      </BCol>
                      <BCol>부저</BCol>
                      <BCol>
                        <CSwitch class="mr-1" color="info" :checked.sync="alarm.lamp.buzzer" label/>
                      </BCol>
                    </BRow>
                  </BTd>
                </BTr>
                <BTr v-if="false">
                  <BTd></BTd>
                  <BTd class="text-right">
                    <BButton  variant="warning" type="submit"><BIconSave2Fill/> 저 장</BButton>
                  </BTd>
                </BTr>
              </BTableSimple>
              </BForm>
            </CCardBody>
          </CCard>


          <!-- lamp config -->
          <CCard border-color="info">
            <CCardHeader>
              <BRow>
                <BCol v-if="this.$store.state.user.role === 'SA'">
                  <BIconStoplightsFill class="mr-2"/> <strong> 경광등 정보 </strong>
                </BCol>
                <BCol class="text-right">
                  <BButton variant="primary" size="sm" type="button" @click="lampApply">
                    <BIconBootstrapReboot/> 설정 반영
                  </BButton>
                </BCol>
              </BRow>
            </CCardHeader>

            <CCardBody>

              <BRow class="mb-1">
                <BCol>
                  <BInputGroup  size="sm" prepend="경광등추가">
                    <BFormSelect id="newLampLinkType"
                                 v-model="selectedLinkType"
                                 style="background-color:#2f303a"
                                 :options="lampLinkTypeOpts"
                    />
                    <BInputGroupAppend>
                      <BButtonGroup>
                        <BButton variant="info" class="mr-3" size="sm"
                                 :disabled="!selectedLinkType"
                                 @click="addNewLamp">
                          <BIconPlusCircleFill/> 추 가
                        </BButton>
                        <BButton variant="info"
                                 @click="getLamp"
                                 size="sm">
                          <BIconArrowClockwise/>
                        </BButton>
                      </BButtonGroup>
                    </BInputGroupAppend>
                  </BInputGroup>
                </BCol>
              </BRow>

              <BTable
                small
                bordered
                responsive
                selectable
                select-mode="single"
                selected-variant="warning"
                sticky-header
                sticky-column
                ref="lampTable"
                thead-class="text-center"
                head-variant="light"
                class="text-nowrap small text-center"
                :fields="lampFields"
                :items="lampRows"
                @row-selected="lampSelected"
              >
                <template #cell(connSts.connected)="{item}">
                  <BBadge :variant="item.connSts.connected?'success':'danger'">
                    {{ item.connSts.connected?'통신중':'연결없음' }}
                  </BBadge>
                </template>
              </BTable>

              <BCard>
                <BForm @submit="lampSubmit">
                <BTableSimple small v-if="lamp!==null" bordered>
                  <BTr>
                    <BTd>경광등 번호 <br/> </BTd>
                    <BTd>
                      <BRow class="mb-1">
                        <BCol cols="4">
                          <BButton size="sm" variant="outline-success" style="min-width:120px">{{lamp.code+' 번 | '+lamp.name}}</BButton>
                        </BCol>
                        <BCol class="text-right">
                          <BButtonGroup>
                            <BButton size="sm" variant="primary" type="submit" @click="lampSubmit($event)" style="min-width:140px">
                              <BIconUpload/> <b>경광등 정보 저장</b>
                            </BButton>
                            <BButton size="sm" variant="danger" class="ml-2" :disabled="!lamp" @click="deleteLamp" style="min-width:80px">
                              <BIconTrash2Fill/>삭제
                            </BButton>
                          </BButtonGroup>
                        </BCol>
                      </BRow>
                    </BTd>
                  </BTr>
                  <BTr>
                    <BTd width="115">
                      연동 유형
                    </BTd>
                    <BTd>
                      <CSelect size="sm" class="mb-1"
                               horizontal
                               :value.sync="lamp.linkType"
                               :options="lampLinkTypeOpts"
                               readonly/>
                    </BTd>
                  </BTr>
                  <BTr>
                    <BTd>사용 설정</BTd>
                    <BTd>
                      <BRow>
                        <BCol class="small">경광등 사용</BCol>
                        <BCol>
                          <CSwitch color="danger"
                                   :checked.sync="lamp.enabled.device"
                                   v-bind="{labelOn:'ON', labelOff: 'OFF'}"
                                   label/>
                        </BCol>
                        <BCol></BCol><BCol></BCol>
                      </BRow>
                      <BRow>
                        <BCol class="small">
                          라이트 사용
                        </BCol>
                        <BCol class="text-left">
                          <CSwitch color="info"
                                   :checked.sync="lamp.enabled.light"
                                   v-bind="{labelOn:'ON', labelOff: 'OFF'}"
                                   label/>
                        </BCol>
                        <BCol class="small">
                          부저 사용
                        </BCol>
                        <BCol>
                          <CSwitch color="info"
                                   :checked.sync="lamp.enabled.buzzer"
                                   v-bind="{labelOn:'ON', labelOff: 'OFF'}"
                                   label/>
                        </BCol>
                      </BRow>

                    </BTd>
                  </BTr>
                  <BTr>
                    <BTd>경광등 명</BTd>
                    <BTd>
                      <BFormInput size="sm"
                                  id="lampName"
                                  type="text"
                                  :state="lamp.name.length>0"
                                  v-model="lamp.name"
                                  maxLength="20"
                                  required/>
                    </BTd>
                  </BTr>
                  <BTr>
                    <BTd>모델 명</BTd>
                    <BTd>
                      <BFormInput size="sm"
                                  id="lampName"
                                  type="text"
                                  v-model="lamp.modelName"
                                  maxLength="40"/>
                    </BTd>
                  </BTr>
                  <BTr>
                    <BTd>수신 장비</BTd>
                    <BTd>
                      <BRow class="small">
                        <BCol>
                          <BInputGroup size="sm">
                            <BFormSelect size="sm"
                                         style="background-color:#2f303a"
                                         v-model="areaCode"
                                         :options="areaOpts"
                                         required/>
                            <BInputGroupAppend>
                              <BButton variant="info" @click="addArea"><BIconNodePlusFill/> 추가</BButton>
                            </BInputGroupAppend>
                          </BInputGroup>
                        </BCol>
                        <BCol>
                          <BInputGroup size="sm">
                            <BFormSelect size="sm"
                                         style="background-color:#2f303a"
                                         v-model="senderId"
                                         :options="tankOpts"
                                         required/>
                            <BInputGroupAppend size="sm">
                              <BButton variant="info" @click="addSenderId"><BIconNodePlusFill/> 추가</BButton>
                            </BInputGroupAppend>
                          </BInputGroup>
                        </BCol>
                      </BRow>
                      <BRow>
                        <BCol>
                          <BBadge variant="primary"
                                  class="mr-1"
                                  v-b-tooltip.hover
                                  title="클릭 삭제"
                                  v-for="(v,i) of lamp.senderIds" :key="i"
                                  @click="delSenderId(v)">{{v}}
                          </BBadge>
                        </BCol>
                      </BRow>
                    </BTd>
                  </BTr>
                  <BTr>
                    <BTd>
                      송신간격
                    </BTd>
                    <BTd>
                      <BRow>
                        <BCol>
                          <BInputGroup size="sm" prepend="신호전송(초)">
                            <BFormInput size="sm"
                                        id="duration"
                                        type="number"
                                        :state="lamp.duration > 0"
                                        v-model="lamp.duration"
                                        required/>
                          </BInputGroup>
                        </BCol>

                        <BCol>
                          <BInputGroup size="sm" prepend="리셋전송(초)">
                            <BFormInput size="sm"
                                        id="stopDuration"
                                        type="number"
                                        :state="lamp.stopDuration > 0"
                                        v-model="lamp.stopDuration"
                                        required/>
                          </BInputGroup>
                        </BCol>
                      </BRow>
                    </BTd>
                  </BTr>
                </BTableSimple>

                <BTableSimple small bordered v-if="lamp!==null && lamp.linkType==='usb'" class="small">
                  <BTr v-show="lamp.linkType==='usb'">
                    <BTd>
                      경광등 ID
                    </BTd>
                    <BTd>

                      <BInputGroup size="sm" prepend="VID">
                        <BFormInput size="sm"
                                    id="vid"
                                    type="text"
                                    :state="isValidExp(lamp.usb.vendorId, 'hex',4)"
                                    v-model="lamp.usb.vendorId"
                                    maxLength="4"
                                    @input="v=>(lamp.usb.vendorId = v.toUpperCase())"
                                    required/>
                      </BInputGroup>

                      <BTooltip target="vid"
                                variant="danger"
                                :show="!(isValidExp(lamp.usb.vendorId, 'hex',4))"
                                placement="right" triggers="manual">
                        16진수 4자 입력
                      </BTooltip>

                      <BInputGroup size="sm" prepend="PID" class="mt-1">
                        <BFormInput size="sm"
                                    id="pid"
                                    type="text"
                                    :state="isValidExp(lamp.usb.productId, 'hex',4)"
                                    v-model="lamp.usb.productId"
                                    maxLength="4"
                                    @input="v=>(lamp.usb.productId = v.toUpperCase())"
                                    required/>
                      </BInputGroup>

                      <BTooltip target="pid"
                                variant="danger"
                                :show="!(isValidExp(lamp.usb.productId, 'hex',4))"
                                placement="right" triggers="manual">
                        16진수 4자 입력
                      </BTooltip>

                      <BInputGroup size="sm" prepend="인터페이스 번호" class="mt-1">
                        <BFormInput size="sm"
                                    id="ifid"
                                    type="number"
                                    v-model="lamp.usb.interfaceId"
                                    v-b-tooltip.hover title="동종 경광등이 복수개가 설치된 경우 0,1,2 순으로 증가함"
                                    maxLength="4"
                                    required/>
                      </BInputGroup>

                    </BTd>
                  </BTr>

                  <BTr v-show="lamp.linkType==='usb'">
                    <BTd colspan="2">
                        <BTableSimple small borderless>
                          <BTr  v-for="item in lamp.signals.usb" :prepend="eventMap[item.code]" :key="item._id">
                            <BTd width="14%">
                              <BButton block size="sm" class="text-nowrap"
                                       variant="outline-warning"
                                       v-b-tooltip.left="'클릭 테스트'"
                                       @click="testSignals(lamp.code, item) ">
                                {{ eventMap[item.code] }}
                              </BButton>
                            </BTd>
                            <BTd>
                              <BInputGroup class="mb-1" size="sm">
                                <BInputGroupAppend>
                                  <BInputGroupText><BIconLightbulbFill/></BInputGroupText>
                                </BInputGroupAppend>
                                <BFormInput type="number" min="0" max="5"  v-model="item.light.red" class="bg-danger"/>
                                <BFormInput type="number" min="0" max="5"  v-model="item.light.yellow" class="bg-warning"/>
                                <BFormInput type="number" min="0" max="5"  v-model="item.light.green" class="bg-success"/>
                                <BFormInput type="number" min="0" max="5"  v-model="item.light.blue" class="bg-info"/>
                                <BFormInput type="number" min="0" max="5"  v-model="item.light.clear" class="bg-gray-500 mr-1"/>
                                <BInputGroupAppend>
                                  <BInputGroupText><BIconBellFill/></BInputGroupText>
                                </BInputGroupAppend>
                                <BFormInput type="number" min="0" max="5"  v-model="item.buzzer.type"/>
                                <BFormInput type="number" min="0" max="13" v-model="item.buzzer.tone1"/>
                                <BFormInput type="number" min="0" max="13" v-model="item.buzzer.tone2"/>
                              </BInputGroup>
                            </BTd>
                          </BTr>

                        </BTableSimple>

                    </BTd>

                  </BTr>
                </BTableSimple>

                <BTableSimple small bordered v-if="lamp!==null && lamp.linkType==='tcp'" >
                  <BTr v-show="lamp.linkType==='tcp'">
                    <BTd>
                      통신설정
                    </BTd>
                    <BTd>
                      <BRow>
                        <BCol>
                          <BFormGroup
                            size="sm"
                            label="서비스 코드"
                            label-for="companyId"
                            label-cols-md="3"
                            valid-feedback="사용 가능한 코드입니다"
                            invalid-feedback="숫자 4자 입력 예) 0009"
                            :state="isValidExp(lamp.tcp.companyId, 'num', 4)"
                          >
                            <BFormInput size="sm" id="companyId"
                                        v-model="lamp.tcp.companyId"
                                        @input="v=>(lamp.tcp.companyId = v.toUpperCase())"
                                        :state="isValidExp(lamp.tcp.companyId, 'num', 4)"
                                        maxLength="4"
                                        required
                                        trim/>
                          </BFormGroup>

                          <BFormGroup
                            size="sm"
                            label="사이트 코드"
                            label-for="siteId"
                            label-cols-md="3"
                            valid-feedback="사용 가능한 코드입니다"
                            invalid-feedback="16진수 4자 예) 1F30"
                            :state="isValidExp(lamp.tcp.siteId, 'hex', 4)"
                          >
                            <BFormInput size="sm" id="siteId"
                                        v-model="lamp.tcp.siteId" :state="isValidExp(lamp.tcp.siteId, 'hex', 4)"
                                        @input="v=>(lamp.tcp.siteId = v.toUpperCase())"
                                        maxLength="4"
                                        required
                                        trim/>
                          </BFormGroup>
                        </BCol>
                      </BRow>
                      <BRow>
                        <BCol sm="3">IP 주소</BCol>
                        <BCol>
                          <BFormInput size="sm"
                                      id="lampIp"
                                      name="lampIp"
                                      type="text"
                                      :state="isValidExp(lamp.tcp.ip, 'ip')"
                                      v-model="lamp.tcp.ip"
                                      v-show="lamp.linkType==='tcp'"
                                      maxLength="15"
                                      required/>
                          <BTooltip target="lampIp"
                                    variant="warning"
                                    :show="!isValidExp(lamp.tcp.ip, 'ip')"
                                    placement="right" triggers="manual">
                            IP 주소 형식이 아닙니다.
                          </BTooltip>
                        </BCol>
                      </BRow>
                      <BRow>
                        <BCol sm="3">포트번호</BCol>
                        <BCol>
                          <BFormInput size="sm"
                                      id="lampPort"
                                      name="lampPort"
                                      type="number"
                                      :state="lamp.tcp.port > 999 && lamp.tcp.port < 65536"
                                      v-model="lamp.tcp.port"
                                      required/>

                          <BTooltip target="lampPort"
                                    variant="warning"
                                    :show="lamp.tcp.port < 999 || lamp.tcp.port > 65535"
                                    placement="right" triggers="manual">
                            1000 ~ 65535 사이 값
                          </BTooltip>
                        </BCol>
                      </BRow>
                    </BTd>
                  </BTr>
                  <BTr v-show="lamp.linkType==='tcp'">
                    <BTd colspan="2">
                      <BButton block pill size="sm" v-b-toggle.collapse-tcp>
                        TCP 경광등 램프/부저 설정 <BIconLayerBackward class="ml-2 text-info"/>
                      </BButton>
                    </BTd>
                  </BTr>
                  <BTr v-show="lamp.linkType==='tcp'">
                    <BTd colspan="2" >
                      <BCollapse id="collapse-tcp">
                        <BTableSimple small borderless>
                          <BTr  v-for="item in lamp.signals.tcp" :prepend="eventMap[item.code]" :key="item._id">
                            <BTd width="14%">
                              <BButton block size="sm" class="text-nowrap"
                                       variant="outline-warning"
                                       v-b-tooltip.left="'클릭 테스트'"
                                       @click="testSignals(lamp.code, item) ">
                                {{`${eventMap[item.code]} (${item.code})`}}
                              </BButton>
                            </BTd>
                            <BTd style="min-width: 400px">
<!--                              <BInputGroup class="mb-1" size="sm">
                                <BInputGroupAppend>
                                  <BInputGroupText><BIconLightbulbFill/></BInputGroupText>
                                </BInputGroupAppend>
                                <BFormInput type="number" :min="0" :max="7" v-model="item.light" class="bg-info"/>
                                <BInputGroupAppend>
                                  <BInputGroupText><BIconBellFill/></BInputGroupText>
                                </BInputGroupAppend>
                                <BFormInput type="number" :min="0" :max="5"  v-model="item.buzzer"/>
                              </BInputGroup>-->

                              <BInputGroup size="sm">
                                <BInputGroupAppend>
                                  <BInputGroupText>
                                    <BIconLightbulbFill/>
                                  </BInputGroupText>
                                  <BFormSelect size="sm" v-model="item.light"
                                               style="background-color:#2f303a"
                                               :options="lightOpts"/>
                                </BInputGroupAppend>

                                <BInputGroupAppend>
                                  <BButton style="min-width: 80px" size="sm" class="mr-5"
                                           :variant="lightVariant[item.light]?lightVariant[item.light]:'dark'">
                                    {{item.light}}
                                  </BButton>
                                </BInputGroupAppend>
                                <BInputGroupAppend>
                                  <BInputGroupText>
                                    <BIconBellFill/>
                                  </BInputGroupText>
                                  <BFormCheckbox class="ml-2 mt-1" size="lg" v-model="item.buzzer"
                                                 button-variant="info"
                                                 value="1" unchecked-value="0" switch/>
                                </BInputGroupAppend>

                              </BInputGroup>
                            </BTd>
                          </BTr>
                        </BTableSimple>

                      </BCollapse>
                    </BTd>
                  </BTr>
                </BTableSimple>

              </BForm>
              </BCard>

            </CCardBody>

          </CCard>

        </BCol>

        <BCol xl="6">

          <CCard border-color="info">
            <CCardHeader>
              <BRow>
                <BCol>
                  <BIconExclamationTriangleFill class="mr-2"/> <strong> 알림 전송 설정 </strong>
                </BCol>
                <BCol class="text-right">

                  <BButton variant="primary" size="sm" type="submit"
                           @click="noticeSubmit($event)" :disabled="isNoticeApply">

                    <BIconstack font-scale="0.98" :animation="isNoticeApply?'spin':'none'">
                      <BIconBootstrapReboot/>
                    </BIconstack>
                    설정 반영
                  </BButton>

                </BCol>
              </BRow>
            </CCardHeader>

            <CCardBody>
              <CTabs>
                <CTab active>
                  <template slot="title">
                    <CIcon name="cil-speech"/> &nbsp;알림톡 설정
                  </template>

                  <BTableSimple small class="small" v-if="notice!==null">
                    <BTr>
                      <BTd width="30%">
                        알림톡 사용
                      </BTd>
                      <BTd>
                        <CSwitch class="mr-1" color="danger" :checked.sync="notice.atEnabled" label/>
                      </BTd>
                    </BTr>
                    <BTr>
                      <BTd>
                        알림톡 URL <B class="text-danger">*</B>
                      </BTd>
                      <BTd>
                        <BFormInput size="sm"
                                    id="atSvrUrl"
                                    name="atSvrUrl"
                                    type="text"
                                    :state="isValidExp(notice.atSvrUrl, 'url')"
                                    v-model="notice.atSvrUrl"
                                    maxLength="100"
                                    required/>
                        <BTooltip target="noticeName"
                                  variant="warning"
                                  :show="!isValidExp(notice.atSvrUrl, 'url')"
                                  placement="right" triggers="manual">
                          URL 형식이 아닙니다. 예) http://domain.co.kr/api/name
                        </BTooltip>
                      </BTd>
                    </BTr>
                    <BTr>
                      <BTd> 연계시스템 코드 <B class="text-danger">*</B> </BTd>
                      <BTd>
                        <BFormInput size="sm" id="atCode" type="text" v-model="notice.atCode" maxLength="10" required/>
                      </BTd>
                    </BTr>
                    <BTr>
                      <BTd>전송기능코드 <B class="text-danger">*</B></BTd>
                      <BTd>
                        <BFormInput size="sm" id="atFnCode" type="text" v-model="notice.atFnCode" maxLength="30" required/>
                      </BTd>
                    </BTr>
                    <BTr>
                      <BTd>결과확인 기능코드</BTd>
                      <BTd>
                        <BInputGroup size="sm">
                          <BFormInput size="sm" id="atRsFnCode" type="text" v-model="notice.atRsFnCode" maxLength="30" required/>
                          <BInputGroupAppend>
                            <BInputGroupText size="sm">대기시간 (초)</BInputGroupText>
                          </BInputGroupAppend>
                          <BFormInput size="sm" id="atRsTime" type="number" v-model="notice.atRsTime" required/>
                        </BInputGroup>
                      </BTd>
                    </BTr>
                    <BTr>
                      <BTd>템플릿 코드 <B class="text-danger">*</B></BTd>
                      <BTd>
                        <BFormInput size="sm" id="atTmplCode" type="text" v-model="notice.atTmplCode" maxLength="50" required/>
                      </BTd>
                    </BTr>
                    <BTr>
                      <BTd>인증토큰 <B class="text-danger">*</B></BTd>
                      <BTd>
                        <BFormInput size="sm" id="atToken" type="text" v-model="notice.atToken" maxLength="100" required/>
                      </BTd>
                    </BTr>
                    <BTr>
                      <BTd>송신자 번호 <B class="text-danger">*</B></BTd>
                      <BTd>
                        <BFormInput size="sm" id="atSenderNo" type="text" v-model="notice.atSenderNo" maxLength="30" required/>
                      </BTd>
                    </BTr>
                    <BTr>
                      <BTd>전송간격 (분)</BTd>
                      <BTd>
                        <BFormInput size="sm" id="atInterval" type="number" v-model="notice.atInterval"
                                    :state="notice.atInterval > 0 && notice.atInterval < 61" :max="60" :min="1" required/>
                      </BTd>
                    </BTr>
                    <BTr>
                      <BTd>사이트 명 <B class="text-danger">*</B></BTd>
                      <BTd>
                        <BFormInput size="sm" id="atSiteName" type="text" v-model="notice.atSiteName" maxLength="100" required/>
                      </BTd>
                    </BTr>
                    <BTr>
                      <BTd>알림명 텍스트 <B class="text-danger">*</B></BTd>
                      <BTd>
                        <BFormInput size="sm" id="atTitleTxt" type="text" v-model="notice.atTitleTxt" maxLength="100" required/>
                      </BTd>
                    </BTr>
                    <BTr>
                      <BTd>추가 텍스트</BTd>
                      <BTd>
                        <BFormInput size="sm" id="atTitleTxt" type="text" v-model="notice.atAppendTxt" maxLength="100" required/>
                      </BTd>
                    </BTr>

                  </BTableSimple>


                </CTab>
                <CTab>
                  <template slot="title">
                    <CIcon name="cil-screen-smartphone"/> SMS 설정
                  </template>
                  <BTableSimple small class="small" v-if="notice!==null">
                    <BTr>
                      <BTd width="30%">
                        SMS 전송 사용
                      </BTd>
                      <BTd>
                        <CSwitch class="mr-1" color="danger" :checked.sync="notice.enabled"  label/>
                      </BTd>
                    </BTr>
                    <BTr>
                      <BTd>
                        서버 URL
                      </BTd>
                      <BTd>
                        <BFormInput size="sm"
                                    id="noticeName"
                                    name="noticeName"
                                    type="text"
                                    :state="isValidExp(notice.serverUrl, 'url')"
                                    v-model="notice.serverUrl"
                                    maxLength="100"
                                    required/>
                        <BTooltip target="noticeName"
                                  variant="warning"
                                  :show="!isValidExp(notice.serverUrl, 'url')"
                                  placement="right" triggers="manual">
                          URL 형식이 아닙니다. 예) http://domain.co.kr/api/name
                        </BTooltip>
                      </BTd>
                    </BTr>
                    <BTr>
                      <BTd>시스템 구분값</BTd>
                      <BTd>
                        <BFormInput size="sm" id="sysCode" type="text" v-model="notice.sysCode" maxLength="30" required/>
                      </BTd>
                    </BTr>
                    <BTr>
                      <BTd>시스템 코드</BTd>
                      <BTd>
                        <BFormInput size="sm" id="subSysCode" type="text" v-model="notice.subSysCode" maxLength="30" required/>
                      </BTd>
                    </BTr>
                    <BTr>
                      <BTd>체계 URL</BTd>
                      <BTd>
                        <BFormInput size="sm" id="referer" type="text" v-model="notice.referer" maxLength="100" required/>
                      </BTd>
                    </BTr>
                    <BTr>
                      <BTd>송신자 번호</BTd>
                      <BTd>
                        <BFormInput size="sm" id="senderPhNo" type="text" v-model="notice.senderPhNo" maxLength="30" required/>
                      </BTd>
                    </BTr>
                    <BTr>
                      <BTd>전송간격 (분)</BTd>
                      <BTd>
                        <BFormInput size="sm" id="interval" type="number" v-model="notice.interval"
                                    :state="notice.interval > 0 && notice.interval < 61" :max="60" :min="1" required/>
                      </BTd>
                    </BTr>
                    <BTr>
                      <BTd>사이트명(요약)</BTd>
                      <BTd>
                        <BFormInput size="sm" id="siteName" type="text" v-model="notice.siteName" maxLength="100" required/>
                      </BTd>
                    </BTr>
                    <BTr>
                      <BTd>공통 메세지</BTd>
                      <BTd>
                        <BFormInput size="sm" id="message" type="text" v-model="notice.message" maxLength="100" required/>
                      </BTd>
                    </BTr>

                  </BTableSimple>
                </CTab>
                <CTab>
                  <template slot="title">
                    <BIconPersonLinesFill class="mr-1"/> 수신자 설정
                  </template>
                  <notice-user-list/>
                </CTab>
              </CTabs>
            </CCardBody>
          </CCard>

          <notice-log-list/>


        </BCol>
      </BRow>
    </BCard>


  </div>
</template>

<style src="spinkit/spinkit.min.css"></style> <!--animated processing icon-->



<script>
//-------------------------------------------------------------------------------------------------
import '../../common/HelperMixin';
import {
  apiCall,
  clone, setAlarm,
} from '../../common/utils';

import NoticeUserList from '../components/NoticeUserList'
import NoticeLogList from '../components/NoticeLogList'
import store from "@/store";

// import qs from 'querystring';
// import moment from "moment";
/*
const _alarm = {
  code: "1",
  name: "전역알람 설정정보",
  enabled: true,
  console: {icon: true, sound: false, tts: true},
  events: [
    {code: "1", enabled: true,},
    {code: "2", enabled: true,},
    {code: "3", enabled: true,},
    {code: "4", enabled: true,},
    {code: "5", enabled: true,},
    {code: "6", enabled: true,},
    {code: "7", enabled: true,},
    {code: "9", enabled: true,}
  ],
  "lamp": {"light": true, "buzzer": true}
}
 */
const _lamp = {
  code: '',
  name: '',
  modelName: '',
  linkType: '', // tcp, usb, serial
  comment: '',
  enabled: {
    device: true,
    light: true,
    buzzer: true,
  },
  senderIds : [],
  sts: {
    connected: false,
    connectedAt: null,
    message: ''
  },
  usb : {
    vendorId: '191A',
    productId: '8003',
    interfaceId: 0,
  },
  tcp : {
    ip: '127.0.0.1', // 127.0.0.1
    port: 3002, // 공군 3002
    companyId: '0000',
    siteId: '0000'
  },
  serial : {
    portName: '', // COM1 ~ COM15
    baudRate: 9600, // 2400, 4800,9600, 14400, 19200, 38400, 56700, 115200
    databit: 8,  // 7,8
    parity: 'NONE',   // ODD, EVEN, NONE
    stopBit: 1,       // 1,2
    flow: 'NONE', // RTS/CTS , DSR/DTR, NONE
  },
  duration: 10, // 10 seconds
  stopDuration: 60, // 1분
  signals: {
    usb: {},
    tcp: {}
  }
};

const _signal = { };
_signal['usb'] = {
  code: '',
  light: { red: 0, yellow: 0, green: 0, blue: 0, clear: 0},
  buzzer: {type: 1, tone1: 0, tone2: 0}
}
_signal['tcp'] = {
  code: '', light: 1, buzzer: 1
}

const _config_notice = {
  code        : '',
  name        : '알림설정',
  enabled     : true,
  serverUrl   : 'http://oper.hq.af.mil:9999/sms/sendAPI.action',
  serverIp    : '',
  port        : 9999,
  sysCode     : 'ICMS',
  subSysCode  : 'icms001',
  account     : '',
  password    : '',
  accessCode  : '',
  authToken   : '',
  senderPhNo  : '000-0000-0000',
  siteCode    : '',
  referer     : 'http://10.10.10.10:8080',
  siteName    : '00비행단',
  interval    : 3,
  message     : '확인요망',

  atEnabled   : true,
  atSvrUrl    : 'http://server.ip/afums/api/v1.0', // 알림톡 URL
  atSvrIp     : '', // 알림톡 IP
  atSvrPort   : 80,
  atUmsKind   : 'AT', // 발송 구분 코드
  atCode      : 'EASD000', // * 연계코드(AT)
  atFnCode    : 'SUMS000001', // * 연계코드(AT)
  atRsFnCode  : 'SUMS000004', // * 기능결과확인코드(AT)
  atRsTime    : 60, // 결과확인 대기시간 60초
  atToken     : 'XXXXXXXX', // 발송 구분 코드
  atTmplCode  : 'AFUMS-EASD-000', // * 템플리트 코드(AT)
  atSenderNo  : '000-999-9999', // 송신자 전화번호
  atTitleTxt  : 'ATG 체계 경보 알람', // * 알림명 (AT)
  atAppendTxt : '담당자는 즉시확인 및 조치바랍니다.', // * 추가 텍스트 (AT)
  atInterval  : 3,
  atSiteName  : ''
}

//----------------------------------------------------------------------------------------------------
export default {
  name: "Alarms",
  components: {
    NoticeUserList,
    NoticeLogList,
  },

  computed: {},

  data () {
    return {
      selectedLinkType: '',
      senderId : '',
      areaCode: '',
      tankOpts: store.state.tanks['opts'],
      areaMaps: store.state.area['maps'],
      areaCodes: store.state.area['codes'],
      areaOpts: store.state.area['opts'],
      newLamp: null,
      lampLinkTypeOpts: [
        {text:'연결방식 선택',  label:'연결방식 선택', value:''},
        {text:'TCP/IP',     label:'TCP/IP', value:'tcp'},
        {text:'USB/Serial', label:'USB/Serial', value:'usb'}
      ],
      lampFields: [
        {key: 'code', label: '번호' },
        {key: 'name', label: '램프명' },
        {key: 'modelName', label: '모델명' },
        {key: 'linkType', label: '연결방식' },
        {key: 'enabled.device', label: '사용', formatter: v=>{ return v?'사용':'중지'} },
        {key: 'connSts.connected', label: '접속상태' },
        {key: 'connSts.connectedAt', label: '접속일시', formatter: v=>{ return this.toLocalTime(v)} },
        {key: 'connSts.message', label: '상태' },
        {key: 'updId', label: '수정자' },
        {key: 'updatedAt', label: '수정일시', formatter: v=>{ return this.toLocalTime(v)} },
        {key: 'regId', label: '등록자' },
        {key: 'createdAt', label: '등록일시', formatter: v=>{ return this.toLocalTime(v)} },
      ],
      lampRows: [],

      alarm: null,
      lamp: null,
      signals: null,
      notice: null,

      eventMap: this.$store.state.codeMaps['EVENT'],
      isSiteSaving: false,
      isGroupDeleting: false,

      lightOpts: [
        {value: '0', text: 'Off', variant: 'primary'},
        {value: '1', text: 'Red', variant: 'primary'},
        {value: '2', text: 'Green', variant: 'primary'},
        {value: '3', text: 'Blue', variant: 'primary'},
        {value: '4', text: 'Yellow', variant: 'primary'},
        {value: '5', text: 'Clear', variant: 'primary'},
      ],
      lightVariant: {
        '0' : 'dark',
        '1' : 'danger',
        '2' : 'success',
        '3' : 'info',
        '4' : 'warning',
        '5' : 'light',
      },

      accountOpts: [
        {value: '', label: '계정 선택'},
        {value: 'smartpol', label: '[smartpol] 시스템 관리자'}
      ],
      accountMap: {
        smartpol : '시스템관리자'
      },

      selectedGroup: null,
      arCodeMap  : {},
      arCodeOpts  : [],
      downloadReason: '',
      searchWord : '',
      progCount: 0,
      totalCount: 0,
      rows: [],

      paramMap: {},
      numOfRecord: 10,
      siteFields: {

      },
      modalTitle: '',
      tankFormShow: false,

      isNoticeApply: false,

    }

  },
  async created(){
    try{
      // await this.setMachineMap();

      await this.getAlarm();
      await this.getLamp();
      await this.getNotice();



    }catch(err){
      console.error(err);
    }
  },


  mounted() {
    console.log("--- Alarms mounted---------------------");
    // below is not work!
  },

  methods: {

    async getAlarm(){
      try{
        const r = await apiCall('get', `/api/alarm`);
        if(r.code===200){
          this.alarm = r.result;
        }else{
          await this.alertDanger(r.message, r.code);
        }

      }catch(err){
        console.error( err );
        await this.alertDanger(err.message);
      }
    },

    async alarmSubmit(evt){
      // console.log(evt);
      evt.preventDefault();
      // console.log( 'alarmSubmit------------->', this.alarm );
      try{
        const r = await apiCall('put', `/api/alarm/${this.alarm._id}`, this.alarm );
        // console.log('alarmSubmit------------->' , r);
        if(r.code===200) await setAlarm();
        await this.toastResult(r);


      }catch(err){
        console.error(err);
      }
    },



    async getLamp(){
      this.lampRows = [];
      try{
        const r = await apiCall('get', `/api/lamp`);
        if(r.code===200){
          this.lampRows = r.result;
          this.lamp = null;
        }else{
          await this.alertDanger(r.message, r.code);
        }

      }catch(err){
        console.error( err );
        await this.alertDanger(err.message);
      }
    },

    lampSelected(item){
      if( item.length === 0 ) {
        this.lamp = null;
      }else{
        this.lamp = item.pop();
        // console.log( this.lamp );
      }
    },
    async updateLamp(){
      try{
        if( ! await this.confirmModal('선택한 경광등 정보를 수정 하시겠습니까?') ){
          return;
        }

        const r = await apiCall('put', `/api/lamp/${this.lamp._id}`);
        if(r.code===200){
          await this.getLamp();
          await this.alertSuccess('경광등 정보가 수정 되었습니다.');
        }else{
          await this.alertDanger(r.message, r.code);
        }

      }catch(err){
        console.error( err );
        await this.alertDanger(err.message);
      }
    },

    addSenderId(){
      if(this.senderId && this.lamp ){
        this.lamp.senderIds.push(this.senderId);
        this.lamp.senderIds = [...new Set(this.lamp.senderIds)];
        // console.log( this.lamp.senderIds );
      }
    },
    addArea(){
      if(this.areaCode && this.lamp ){
        // console.log( this.areaCodes[this.areaCode]['tanks'] )
        this.areaCodes[this.areaCode]['tanks'].map(t=>{
          this.lamp.senderIds.push(t.tid);
        });
        this.lamp.senderIds = [...new Set(this.lamp.senderIds)];
      }
    },
    delSenderId(v){
      this.lamp.senderIds = this.lamp.senderIds.filter(function(data) {
        return data !== v;
      });
    },
    async addNewLamp(){
      try{
        let newOne;
        if(this.lamp) {
          newOne = clone(this.lamp)
          delete newOne._id;
          if(this.selectedLinkType !== newOne.linkType ){
            await this.alertDanger('동일 연동유형만 복사등록 가능합니다.')
            return;
          }
        }else{
          newOne = clone(_lamp);
          let signals = newOne.signals[this.selectedLinkType];
          Object.keys(this.eventMap).map(code=>{
            let signal = clone( _signal[this.selectedLinkType] );
            signal.code = code;
            signals[signal.code] = signal;
          })
        }

        newOne.name = '경광등(신규)';
        newOne.linkType = this.selectedLinkType;

        const r = await apiCall('post', `/api/lamp`, newOne);
        if(r.code===200){
          await this.getLamp()
          await this.alertSuccess('경광등 등록 성공');
        }else{
          await this.alertDanger(r.message, r.code);
        }
      }catch(err){
        console.error( err );
        await this.alertDanger(err.message);
      }
    },
    async deleteLamp(){
      try{
        if( ! await this.confirmModal(`'${this.lamp.code}번' 경광등 정보를 삭제 하시겠습니까?`) ){
          return;
        }

        const r = await apiCall('del', `/api/lamp/${this.lamp._id}`);
        if(r.code===200){
          await this.getLamp();
          await this.alertSuccess('경광등이 삭제 되었습니다.');
        }else{
          await this.alertDanger(r.message, r.code);
        }

      }catch(err){
        console.error( err );
        await this.alertDanger(err.message);
      }

    },

    async getNotice(){
      try{
        const r = await apiCall('get', `/api/notice`);
        if(r.code===200){
          if(!r.result)
            this.notice = clone(_config_notice);
          else
            this.notice = r.result;

        }else{
          await this.alertDanger(r.message, r.code);
        }

      }catch(err){
        console.error( err );
        await this.alertDanger(err.message);
      }
    },

    async noticeSubmit(evt){
      // console.log(evt);
      let r;
      evt.preventDefault();
      // console.log( 'noticeSubmit------------->', this.notice );
      try{
        this.isNoticeApply = true;
        r = await apiCall('post', `/api/notice`, this.notice );
        // console.log('noticeSubmit result ------------->' , r);
        this.notice = r.result.rs;

        if(!r.result.isSmsLive) this.alertWarn('SMS 서버 통신 장애. SMS서버 설정 확인 요망');
        if(!r.result.isAtLive) this.alertWarn('알림톡 서버 통신 장애. 알림톡서버 설정 확인 요망');

        await this.toastResult(r);
        this.isNoticeApply = false;
      }catch(err){
        console.error(err);
        this.isNoticeApply = false;
      }
    },


    async testSignals(code,item){
      // console.log( "testSignal code ---->", code );
      // console.log( "testSignal item ---->", item );
      try{
        const r = await apiCall('post', `/api/lamp/send/${code}`, item );
        // console.log('lampSubmit------------->' , r);
        await this.toastResult(r);
      }catch(err){
        console.error(err);
      }
    },

    async lampApply(){
      // console.log( "lampApply ---->");
      try{
        const r = await apiCall('get', `/api/lamp/apply` );
        // console.log('lampSubmit------------->' , r);
        await this.toastResult(r);
      }catch(err){
        console.error(err);
      }
    },


    async lampSubmit(evt){
      evt.preventDefault();
      // console.log( 'lampSubmit------------->', evt );
      try{
        const r = await apiCall('put', `/api/lamp/${this.lamp._id}`, this.lamp );
        // console.log('lampSubmit------------->' , r);
        await this.toastResult(r);
      }catch(err){
        console.error(err);
      }
    },



  }
}
</script>
